import React from "react";
import "./Footer.css";
import { Link } from 'react-router-dom';

export default function Footer() {
    return (
        <div>
            <div className="row no-margins">
                <div className="col-sm-12 beauty-by-sofie-bright-footer ">
                    <div className="row beauty-by-sofie-bright-footer-content-row">
                        <div className="col-sm-3"></div>
                        <div className="col-sm-6">
                            <div className="row">
                                <div className="col-sm-4 beauty-by-sofie-bright-footer-column-1">
                                    <p className="beauty-by-sofie-bright-footer-column-header-1">BLIQ Fransar & Bryn</p>
                                    <ul>
                                        <Link to="/about">
                                            <li>Om mig</li>
                                        </Link>
                                        <Link to="/terms">
                                            <li>Villkor</li>
                                        </Link>
                                        <Link to="/faqs">
                                            <li>Vanliga frågor</li>
                                        </Link>
                                    </ul>
                                </div>
                                <div className="col-sm-4">
                                    <div className="beauty-by-sofie-bright-footer-column-2">
                                        <p className="beauty-by-sofie-bright-footer-column-header-2">Behandlingar</p>
                                        <ul>
                                            <Link to="/lifts">
                                                <li>Lash Lift & Brow Lift</li>
                                            </Link>
                                            <Link to="/lashes">
                                                <li>Fransförlängning</li>
                                            </Link>
                                            <Link to="/colorings">
                                                <li>Färgning av Fransar & Bryn</li>
                                            </Link>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-sm-4 beauty-by-sofie-bright-footer-column-3">
                                    <p className="beauty-by-sofie-bright-footer-column-header-3">Kontakt</p>
                                    <ul>
                                        <li>070 729 72 85</li>
                                        <Link to="mailto:info@dinbliq.se?Subject=Hej%20Sofie">
                                            <li>info@dinbliq.com</li>
                                        </Link>
                                        <Link to="https://instagram.com/beeautybysofie?igshid=1uouc9hmrszhh" target="_blank">
                                            <li>Instagram</li>
                                        </Link>
                                        <Link to="https://www.facebook.com/beauty-by-sofie/" target="_blank">
                                            <li>Facebook</li>
                                        </Link>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-3"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}