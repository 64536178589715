import React from "react";
import "./Gallery.css";
import Header from "../../header/Header";
import Footer from "../../footer/Footer";
import jquery from "jquery";

export default function Gallery() {
    const $: JQueryStatic = jquery;
    window.onload = () => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
    };

    const images = {
        browLift: [
            require('../../images/gallery/browlift/BrowLift1.jpg'),
            require('../../images/gallery/browlift/BrowLift2.jpg'),
            require('../../images/gallery/browlift/BrowLift3.JPG'),
            require('../../images/gallery/browlift/BrowLift4.JPG'),
            require('../../images/gallery/browlift/BrowLift5.JPG'),
            require('../../images/gallery/browlift/BrowLift6.jpg'),
            require('../../images/gallery/browlift/BrowLift7.JPG')
        ],
        lashLift: [
            require('../../images/gallery/lashlift/LashLift1.JPG'),
            require('../../images/gallery/lashlift/LashLift2.JPG'),
            require('../../images/gallery/lashlift/LashLift3.JPG'),
            require('../../images/gallery/lashlift/LashLift4.JPG'),
            require('../../images/gallery/lashlift/LashLift5.JPG'),
            require('../../images/gallery/lashlift/LashLift6.JPG'),
            require('../../images/gallery/lashlift/LashLift7.JPG')
        ],
        lashExtension: [
            require('../../images/gallery/lashextension/LashExtension1.jpg'),
            require('../../images/gallery/lashextension/LashExtension2.jpg'),
            require('../../images/gallery/lashextension/LashExtension3.jpg'),
            require('../../images/gallery/lashextension/LashExtension4.jpg'),
            require('../../images/gallery/lashextension/LashExtension5.jpg'),
            require('../../images/gallery/lashextension/LashExtension6.jpg'),
            require('../../images/gallery/lashextension/LashExtension7.jpg')
        ]
    };

    function openImageModal(image: string) {
        var modal = document.getElementById("imageModal");
        if (modal) modal.style.display = "block";
        var imageElement = document.getElementById('imageModalImage') as HTMLImageElement;
        imageElement.src = image;

    };


    function dropDownGallery(index: number) {
        var element = $(".inner-gallery-" + index);
        if (element[0].style.display === '' || element[0].style.display === 'none') {
            element.slideDown();
        } else {
            element.slideUp();
        }
    }

    function closeImageModal() {
        var modal = document.getElementById("imageModal");
        if (modal) modal.style.display = "none";
    };

    return (
        <div>
            <Header></Header>
            <div className="row gallery-body">
                <div id="imageModal" className="modal">
                    <div className="modal-content">
                        <span onClick={() => closeImageModal()} className="close">&times;</span>
                        <img id="imageModalImage" alt="" />
                    </div>
                </div>

                <div className="col-sm-12 gallery-content-box gallery-content-box-1">
                    <div className="row">
                        <div className="col-sm-6 inline-flex">
                            <div className="clickable-gallery" onClick={() => dropDownGallery(1)}>
                                <h1 className="animated fadeInUp">Lash Lift</h1>
                                <p className="animated pulse infinite"><span className="glyphicon glyphicon-chevron-down"></span></p>
                            </div>
                        </div>
                        <div className="col-sm-6" style={{overflowX:`hidden`}}>
                            <img className="animated fadeInRight" src={require('../../images/gallery/categoryimages/LashLiftGallery.png')} alt="" />
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 no-padding">
                    <div className="inner-gallery-1">
                        <div className="row">
                            <div className="col-sm-1"></div>
                            <div className="col-sm-10">
                                {
                                    images.lashLift.map((image, index) => 
                                        <img key={index} onClick={() => openImageModal(image)} className="make-it-slow" src={image} alt="" />
                                    )
                                }
                            </div>
                            <div className="col-sm-1"></div>
                        </div>
                    </div>
                </div>

                <div className="col-sm-12 gallery-content-box gallery-content-box-2">
                    <div className="row">
                        <div className="col-sm-6">
                            <img className="animated fadeInLeft" src={require('../../images/gallery/categoryimages/BrowLiftGallery.png')} alt="" />
                        </div>
                        <div className="col-sm-6 inline-flex">
                            <div className="clickable-gallery" onClick={() => dropDownGallery(2)}>
                                <h1 className="animated fadeInUp">Brow Lift</h1>
                                <p className="animated pulse infinite"><span className="glyphicon glyphicon-chevron-down"></span></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 no-padding">
                    <div className="inner-gallery-2">
                        <div className="row">
                            <div className="col-sm-1"></div>
                            <div className="col-sm-10">
                                {
                                    images.browLift.map((image, index) => 
                                        <img key={index} onClick={() => openImageModal(image)} className="make-it-slow" src={image} alt="" />
                                    )
                                }
                            </div>
                            <div className="col-sm-1"></div>
                        </div>
                    </div>
                </div>

                <div className="col-sm-12 gallery-content-box gallery-content-box-3">
                    <div className="row">
                        <div className="col-sm-6 inline-flex">
                            <div className="clickable-gallery" onClick={() => dropDownGallery(3)}>
                                <h1 className="scroll-animations-animated animated">Fransförlängning</h1>
                                <p className="animated pulse infinite"><span className="glyphicon glyphicon-chevron-down"></span></p>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <img className="animated fadeInRight" src={require('../../images/gallery/categoryimages/LashExtensionGallery.png')} alt="" />
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 no-padding">
                    <div className="inner-gallery-3">
                        <div className="row">
                            <div className="col-sm-1"></div>
                            <div className="col-sm-10">
                                {
                                    images.lashExtension.map((image, index) => 
                                        <img key={index} onClick={() => openImageModal(image)} className="make-it-slow" src={image} alt="" />
                                    )
                                }                                
                            </div>
                            <div className="col-sm-1"></div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </div>
    )
}