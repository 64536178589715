import React from "react";
import { Link } from 'react-router-dom'
import "./Partners.css";
import Header from "../../header/Header";
import Footer from "../../footer/Footer";

export default function Partners() {
    window.onload = () => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
    };

    return (
        <div>
            <Header></Header>
            <div className="row partners-body no-margins">
                <div className="col-sm-12">
                    <div className="row">
                        <div className="col-sm-3"></div>
                        <div className="col-sm-6 partners-body-content">
                            <h1 className="animated fadeInUp" style={{display:`block`}}>Samarbetspartners</h1>
                            <div className="partners-images">
                                <div className="inner-partners animated fadeInUp">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <Link to="https://www.mandymedical.se/" target="_blank">
                                                <img src={'https://static.wixstatic.com/media/e1e34d_69d9cef2e03f4d149bf13de0cf262077~mv2.png/v1/fill/w_328,h_152,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/thumbnail_Final%20MM%20logo%20nr%202%20vectorised_.png'} alt="" />
                                            </Link>
                                        </div>
                                        <div className="col-sm-6">
                                            <Link to="https://www.lysterhudvard.se/" target="_blank">
                                                <img src={'https://static.wixstatic.com/media/47a0ff_5fc988a9798845bca29bda104dc03cfe~mv2.jpg/v1/crop/x_356,y_234,w_1096,h_318/fill/w_392,h_114,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/Logga%20VIT.jpg'} alt="" />
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="outer-partners">
                                    <Link to="https://www.bokadirekt.se/" target="_blank">
                                        <img className="animated fadeInUp" src={'https://static.wixstatic.com/media/e1e34d_934acd93627342f6a2b1ba966b27f8f0~mv2.png/v1/crop/x_4,y_0,w_240,h_179/fill/w_336,h_251,al_c,lg_1,q_85,enc_auto/bokadirekt-logo2_png%20microneedling-kemiskpeeling-mandymedical-sjuksk%C3%B6terska-sollentuna-sk%C3%B6.png'} alt="" />
                                    </Link>
                                    <Link to="https://www.zettle.com/se" target="_blank">
                                        <img className="animated fadeInUp" src={'https://static.wixstatic.com/media/e1e34d_5c06fbd4c08f4c0e86deb37ecf02e0da~mv2.png/v1/crop/x_0,y_37,w_162,h_71/fill/w_227,h_99,al_c,lg_1,q_85,enc_auto/logga%20izattle.png'} alt="" />
                                    </Link>
                                    <Link to="https://www.klarna.com/se/" target="_blank">
                                        <img className="animated fadeInUp" src={'https://static.wixstatic.com/media/e1e34d_8207d1ba5b5e4822bc93fe8f2d6f4e44~mv2.png/v1/crop/x_16,y_0,w_183,h_104/fill/w_256,h_146,al_c,lg_1,q_85,enc_auto/logga%20klarna.png'} alt="" />
                                    </Link>
                                    <Link to="https://www.swish.nu/" target="_blank">
                                        <img className="animated fadeInUp" src={'https://static.wixstatic.com/media/e1e34d_7c1d4ee803734d44bd0e4c733502992d~mv2.jpeg/v1/crop/x_24,y_7,w_173,h_214/fill/w_208,h_260,al_c,lg_1,q_80,enc_auto/images.jpeg'} alt="" />
                                    </Link>
                                </div>
                            </div>

                        </div>
                        <div className="col-sm-3"></div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </div>
    )
}