import React from "react";
import "./Terms.css";
import Header from "../../header/Header";
import Footer from "../../footer/Footer";

export default function Terms() {
    window.onload = () => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
    };

    return (
        <div>
            <Header></Header>
            <div className="row beauty-by-sofie-terms-body no-margins">
                <div className="col-sm-12 beauty-by-sofie-terms-body-content">
                    <div className="beauty-by-sofie-terms-body-content-box">
                        <div className="beauty-by-sofie-terms-body-content-box-text">
                            <h1>Villkor</h1>
                            <p>1. Kom alltid helt osminkad runt ögonen då smink försvårar arbetet och gör att jag inte kan garantera bästa möjliga resultat under din bokade tid.</p>
                            <p>2. Avbokning sker 24 timmar innan din bokade tid. Annars debiteras fullt pris.</p>
                            <p>3. Kom gärna 5 minuter innan din bokade tid för eventuella nödvändigheter, som till exempel toalettbesök, då den bokade tiden räknas som effektiv arbetstid.</p>
                            <p>4. Det är alltid upp till kunden själv att följa skötselråden som ges vid behandlingstillfället.</p>
                            <p>5. Avboka alltid din tid om du är sjuk.</p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </div>
    )
}