import React from "react";
import "./Lashes.css";
import Header from "../../../header/Header";
import Footer from "../../../footer/Footer";
import jquery from "jquery";
import { Link } from "react-router-dom";

export default function Lashes() {
    const $: JQueryStatic = jquery;
    
    window.onload = () => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
    };

    $('.lashes-body-column-border-rotate-1').slideDown();
    $('.lashes-body-column-border-rotate-2').slideDown();
    $('.lashes-body-column-border-rotate-3').slideDown();

    return (
        <div>
            <Header></Header>
            <div className="row no-margins lashes-body-small">
                <div className="col-sm-12 lashes-body-small-column-1 no-padding">
                    <img src={require('../../../images/treatments/CategorySingle.jpg')} alt="" />
                    <h1 className="animated fadeInUp">SINGEL</h1>
                    <p className="animated fadeInUp">Resultatet av singelfransar blir en mascara-effekt fast tjockare och längre fransar. Jag fäster en singelfrans på varje naturlig frans.</p>
                    <button className="animated fadeInUp"><Link to="/lashesSingle">Läs mer / Boka</Link></button>
                </div>
                <div className="col-sm-12 lashes-body-small-column-2 no-padding">
                    <img src={require('../../../images/treatments/CategoryVolume.jpg')} alt="" />
                    <h1 className="animated fadeInUp">VOLYM</h1>
                    <p className="animated fadeInUp">Volymfransarna är mycket tunnare än singelfransarna, vilket gör att man fäster de i knippen med 2-5 fransar i varje. Här kan du få ett fylligt resultat även om du har glesa fransar.</p>
                    <button className="animated fadeInUp"><Link to="/lashesVolume">Läs mer / Boka</Link></button>
                </div>
                <div className="col-sm-12 lashes-body-small-column-3 no-padding">
                    <img src={require('../../../images/treatments/CategoryMix.jpg')} alt="" />
                    <h1 className="animated fadeInUp">MIX</h1>
                    <p className="animated fadeInUp">Här blandar vi singelfransar och volymfransar för ett naturligt, men fylligare resultat.</p>
                    <button className="animated fadeInUp"><Link to="/lashesMix">Läs mer / Boka</Link></button>

                </div>

            </div>

            <div className="row no-margins lashes-body">
                <div className="col-sm-12">
                    <div className="row">
                        <div className="lashes-body-column-border-rotate-1">
                            <div className="lashes-image-box">
                                <img src={require('../../../images/treatments/CategorySingle.jpg')} alt="" />
                            </div>
                        </div>
                        <div className="lashes-body-column-border-rotate-2">
                            <div className="lashes-image-box">
                                <img src={require('../../../images/treatments/CategoryVolume.jpg')} alt="" />
                            </div>
                        </div>
                        <div className="lashes-body-column-border-rotate-3">
                            <div className="lashes-image-box">
                                <img src={require('../../../images/treatments/CategoryMix.jpg')} alt="" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-sm-12">
                    <div className="row">
                        <div className="col-sm-4 lashes-body-column-1">
                            <div className="lashes-body-column-content">
                                <h1 className="animated fadeInUp">SINGEL</h1>
                                <p className="animated fadeInUp">Resultatet av singelfransar blir en mascara-effekt fast tjockare och längre fransar. Jag fäster en singelfrans på varje naturlig frans.</p>
                                <button className="animated fadeInUp"><Link to="/lashesSingle">Läs mer / Boka</Link></button>
                            </div>
                        </div>
                        <div className="col-sm-4 lashes-body-column-2">
                            <div className="lashes-body-column-content">
                                <h1 className="animated fadeInUp">VOLYM</h1>
                                <p className="animated fadeInUp">Volymfransarna är mycket tunnare än singelfransarna, vilket gör att man fäster de i knippen med 2-5 fransar i varje. Här kan du få ett fylligt resultat även om du har glesa fransar.</p>
                                <button className="animated fadeInUp"><Link to="/lashesVolume">Läs mer / Boka</Link></button>
                            </div>
                        </div>
                        <div className="col-sm-4 lashes-body-column-3">
                            <div className="lashes-body-column-content">
                                <h1 className="animated fadeInUp">MIX</h1>
                                <p className="animated fadeInUp">Här blandar vi singelfransar och volymfransar för ett naturligt, men fylligare resultat.</p>
                                <button className="animated fadeInUp"><Link to="/lashesMix">Läs mer / Boka</Link></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </div>
    )
}