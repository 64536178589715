import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/home/Home";
import About from "./pages/about/About";
import Partners from "./pages/partners/Partners";
import FAQS from "./pages/faqs/FAQS";
import Gallery from "./pages/gallery/Gallery";
import Prices from "./pages/prices/Prices";
import Training from "./pages/training/Training";
import Treatments from "./pages/treatments/Treatments";
import Lifts from "./pages/treatments/lifts/Lifts";
import Colorings from "./pages/treatments/colorings/Colorings";
import Lashes from "./pages/treatments/lashes/Lashes";
import LashesSingle from "./pages/treatments/lashes/lashesSingle/LashesSingle";
import LashesVolume from "./pages/treatments/lashes/lashesVolume/LashesVolume";
import LashesMix from "./pages/treatments/lashes/lashesMix/LashesMix";
import Terms from './pages/terms/Terms';

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home></Home>} />
        <Route path="/treatments" element={<Treatments></Treatments>} />
        <Route path="/training" element={<Training></Training>} />
        <Route path="/prices" element={<Prices></Prices>} />
        <Route path="/gallery" element={<Gallery></Gallery>} />
        <Route path="/about" element={<About></About>} />
        <Route path="/faqs" element={<FAQS></FAQS>} />
        <Route path="/partners" element={<Partners></Partners>} />
        <Route path="/lifts" element={<Lifts></Lifts>} />
        <Route path="/colorings" element={<Colorings></Colorings>} />
        <Route path="/lashes" element={<Lashes></Lashes>} />
        <Route path="/lashesSingle" element={<LashesSingle></LashesSingle>} />
        <Route path="/lashesVolume" element={<LashesVolume></LashesVolume>} />
        <Route path="/lashesMix" element={<LashesMix></LashesMix>} />
        <Route path="/terms" element={<Terms></Terms>} />
      </Routes>
    </BrowserRouter>
  );
}
