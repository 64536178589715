import React from "react";
import Header from "../../../../header/Header";
import Footer from "../../../../footer/Footer";
import ServiceComponent from "../../../../components/service/ServiceComponent";

export default function LashesMix() {
    window.onload = () => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
    };

    return (
        <div>
            <Header></Header>
            <ServiceComponent serviceId={4059}></ServiceComponent>
            <Footer></Footer>
        </div>
    )
}