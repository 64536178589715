import React from "react";
import "./Training.css";
import { Link } from 'react-router-dom';
import Header from "../../header/Header";
import Footer from "../../footer/Footer";

export default function Training() {
    window.onload = () => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
    };
    
    return (
        <div>
            <Header></Header>
            <div className="row training-body-1 no-margins">
                <div className="col-sm-12 no-padding">
                    <div className="home-parallax training-image"></div>
                    <div className="row no-margin">
                        <div className="col-xs-1 col-sm-1 col-md-1"></div>
                        <div className="d-none d-xl-block col-md-0 col-lg-0 col-xl-5">
                            <img className="certificate-image" src={require("../../images/education/SofieCertificate.png")} style={{width:`600px`,position:`relative`,top:`-760px`}} alt="" />
                        </div>
                        <div className="col-xs-10 col-sm-10 col-md-10 col-lg-10 col-xl-5 training-body-1-content">
                            <h1 className="animated fadeInUp">Lashlift Utbildning</h1>
                            <p className="animated fadeInUp">
                                Vill du få in en fot i den ( enligt mig ) roligaste branchen - skönhetsbranchen?
                                Eller jobbar du med andra typer av skönhetsbehandlingar och vill utöka din verksamhet?
                            </p>
                            <br />
                            <p className="animated fadeInUp">
                                Lashlift har blivit en av de populäraste behandlingarna och passar unga som gamla,
                                kvinnor som män som vill framhäva sina fransar på ett naturligt och skonsamt sätt.
                            </p>
                            <br />
                            <p className="animated fadeInUp">
                                Utbildningen är en heldagsutbildning och kommer innehålla en teoretisk del och en praktisk del där du själv får utföra behandlingen.
                                Därefter får du ett certifikat. Även ett fullpackat startkit ingår som räcker till minst 10 behandlingar efter utbildningen.
                            </p>
                            <br />
                            <p className="animated fadeInUp">
                                Vi kommer hålla till på Mandy Medical i Häggvik på antingen lördagar eller söndagar mellan kl. 10.00 och 15.00.
                            </p>
                            <br />
                            <p className="animated fadeInUp">
                                Priset för utbildningen är 5.000kr inkl. moms. En depositionsavgift på 1.500 kr betalas en vecka före utbildningen och resterande belopp betalas vid ankomst.
                                Delbetalning med Klarna Faktura är möjligt.
                                Ta med egen modell att utföra behandlingen på. Det är viktigt att din modell inte har ögonfransförlängning eller har gjort en lashlift de senaste 6 veckorna.
                                Har du inte möjlighet att ta med dig en modell kan du höra av dig till mig så ordnar jag det.
                            </p>
                            <br />
                            <p className="animated fadeInUp">
                                Vid bokning av utbildningen kommer du få ett mail med all information du behöver.
                            </p>
                            <Link to="https://www.bokadirekt.se/boka-tjanst/mandy-medical-4448/lash-lift-utbildning-1214584" target="_blank">
                                <button className="animated fadeInUp">Boka tid</button>
                            </Link>
                        </div>
                        <div className="col-xs-1 col-sm-1 col-md-1"></div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </div>
    )
}