import React from 'react';
import { Link } from 'react-router-dom';
import jquery from 'jquery';
import './Home.css';
import Header from '../../header/Header';
import Footer from "../../footer/Footer";

export default function Home() {
    const $: JQueryStatic = jquery;
    window.onload = () => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
    };
    
    var reviewIndex = 1;
    var reviews = [
        "\"Sofie är fantastisk på det hon gör! Jag har gjort lashlift och färgning av bryn och har aldrig varit nöjdare. Hon lyssnar till ens önskemål och ser alltid till att man är tillfredsställd med besöket. Rekommenderar starkt att gå till henne när det gäller beauty för hon är pålitlig och supertrevlig!\"",
        "\"Jag gör volymfransar hos Sofie och hon är den bästa av alla som jag gått hos! Hon är noggrann, snabb, världens trevligaste tjej och professionell samtidigt. Resten av gänget är supertrevliga också. Parkering är lätt att hitta.\"",
        "\"Gått till Sofie i flera år nu, finns ingen som gör mina fransar och bryn så bra som henne. Hon är super duktig, snabb, trevlig och generös/hjälpsam. Finns ingenting att klaga på\"",
        "\"Fräsch lokal och man kände sig trygg i Sofies händer. Gjorde lashlift och hon var lätt på handen och förklarade hela tiden vad hon skulle göra och vad som hände. Sjukt nöjd med resultatet också.\"",
        "\"Sofie är extremt duktig och supertrevlig. Bästa fransstylisten jag varit hos! Alltid fina fransar som håller bra. Rekommenderas varmt.\"",
        "\"Alltid jättenöjd när man går ifrån salongen och Sofie.\"",
        "\"Sofie är underbar och oerhört proffsig, rekommenderar starkt\"",
    ];
    
    $(document).ready(function() {
        $('.home-review-text')[0].innerText = reviews[0];
        $('.home-review-text').fadeIn();
        setInterval(function() {
            $('.home-review-text').fadeOut(function() {
                $('.home-review-text')[0].innerText = reviews[reviewIndex];
                $('.home-review-text').fadeIn();
                if (reviewIndex === 6) {
                    reviewIndex = 0;
                } else {
                    reviewIndex++;
                }
            });
        }, 10000);
    
        function isScrolledIntoView(elem: any) {
            var wnw = $(window);
            var docViewTop = wnw.scrollTop();
            if (docViewTop && wnw) {
                var windowHeight = wnw.height()
                if (windowHeight) {
                    var height = windowHeight | 0;
                    var docViewBottom = docViewTop + height;
                    var offset = $(elem).offset();
                    var elemTop = offset && offset.top - 100;
                    var elemHeight = $(elem).height()
                    if (elemTop && elemHeight) {
                        var elemBottom = elemTop + elemHeight;
                        return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
                    }
                }
            }
        }
    
        $(window).scroll(function() {
            $('.scroll-animations-animated').each(function() {
                if (isScrolledIntoView(this) === true) {
                    $(this).addClass('fadeInUp');
                }
            });
        });
    });


    return (
        <div>
            <Header></Header>
            <div className="row home-body-1 no-margins">
                <div className="col-sm-12 no-padding">
                    <img src={require('../../images/home/HomeGif.gif')} alt="" />
                </div>
            </div>

            <div className="row home-body-2 no-margins">
                <div className="col-sm-12 no-padding" style={{ height:`100px`}}>
                    <div className="home-body-2-welcome-header">
                        <div className="animated fadeInUp">
                            <h1>Välkommen</h1>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 no-padding">
                    <div className="row">
                        <div className="col-sm-3"></div>
                        <div className="col-sm-6">
                            <div className="home-body-2-welcome-text">
                                <p className="animated fadeInUp">Jag heter Sofie Eriksson och driver BLIQ Fransar & Bryn sedan januari 2011. Jag hyr ett rum på skönhetssalongen Mandy Medical i Häggvik där jag utför en rad olika behandlingar som du kan läsa mer om på den här sidan. Här kan du även boka tid, se bilder på de olika behandlingarna och läsa recensioner från kunder.</p>
                                <br />
                                <p className="animated fadeInUp">Har du några frågor kan du kontakta mig. Kontaktuppgifterna hittar du längst ner på sidan. Jag hoppas du hittar det du söker och jag ser fram emot att få ta hand om dig på salongen!</p>
                            </div>
                            <div className="home-body-2-welcome-profile-pic">
                                <img src={require('../../images/home/ProfilePic.JPG')} alt="" />
                            </div>
                        </div>
                        <div className="col-sm-3"></div>
                    </div>
                </div>
            </div>

            <div className="row home-body-3 no-margins">
                <div className="col-sm-12 col-md-12 col-lg-6 no-padding">
                    <div className="home-body-3-book-text">
                        <h1 className="scroll-animations-animated animated">Boka tid</h1>
                        <p className="scroll-animations-animated animated">
                            Klicka
                            <Link to="treatments"> här </Link>
                            för att boka en tid för önskad behandling. Där kan du även läsa mer om de olika behandlingarna jag erbjuder.
                        </p>
                    </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-6 no-padding">
                    <div className="home-body-3-book-button">
                        <Link to="/treatments">
                            <div className="inline-flex">
                                <img src={require('../../images/home/BookTime.png')} className="animated slideInRight slower" alt="" />
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="row home-body-4 no-margins">
                <div className="home-parallax"></div>
                <div className="col-sm-3"></div>
                <div className="col-sm-6 home-body-4-content">
                    <h4 className="scroll-animations-animated animated">Nyhet</h4>
                    <h1 className="scroll-animations-animated animated">Lashlift Utbildning</h1>
                    <p className="scroll-animations-animated animated">
                        Vill du få in en fot i skönhetsbranchen, få en extrainkomst eller utöka din nuvarande verksamhet?
                        Från och med nu kan du utbilda dig i Lashlift hos mig! Utbildningen är en endags-utbildning där certifikat och start-kit ingår.
                        Inga förkunskaper krävs!
                        Kontakta mig här om du är intresserad eller boka en tid.
                    </p>
                    <p style={{fontSize:`15px`}}>Pris: 5.000kr ( inkl. moms )</p>
                    <Link to="training"><button className="scroll-animations-animated animated">Läs mer</button></Link>
                    <Link to="https://www.bokadirekt.se/boka-tjanst/mandy-medical-4448/lash-lift-utbildning-1214584" target="_blank"><button className="scroll-animations-animated animated">Boka tid</button></Link>
                </div>
                <div className="col-sm-3"></div>
            </div>

            <div className="row home-body-5 no-margins">
                <div className="col-sm-3"></div>
                <div className="col-sm-6 home-body-5-content">
                    <p className="home-review-text"></p>
                    <Link to="https://www.hitta.se/sofie+hammarstr%C3%B6m/sollentuna/mqttbhcm" target="_blank">
                        <button className="animated fadeInUp">Läs fler recensioner</button>
                    </Link>
                </div>
                <div className="col-sm-3"></div>
            </div>

            <div className="row home-body-6 no-margins">
                <div className="col-sm-12 no-padding">
                    <div className="row home-body-6-find-us-header">
                        <div className="col-sm-12 animated fadeIn slowest">
                            <h1 className="scroll-animations-animated animated">Hitta hit</h1>
                        </div>
                    </div>
                    <div className="row home-body-6-find-us-content">
                        <div className="col-sm-6">
                            <img src={require('../../images/home/FindUs.jpg')} alt="" />
                        </div>
                        <div className="col-sm-6">
                            <p className="scroll-animations-animated animated upper-text" style={{fontWeight:`bold`, color:`rgba(0,0,0,.5)`}}>Mandy Medical</p>
                            <p className="scroll-animations-animated animated" style={{color:`rgba(0,0,0,.4)`}}>Studievägen 10</p>
                            <p className="scroll-animations-animated animated" style={{color:`rgba(0,0,0,.4)`}}>191 50 Sollentuna</p>
                            <div className="inline-flex">
                                <span className="glyphicon glyphicon-chevron-right"></span>
                                <p className="scroll-animations-animated animated" style={{color:`rgba(0,0,0,.4)`,fontStyle:`italic`}}>350 meter från Häggviks station</p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </div>
    )
}