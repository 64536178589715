import React from "react"
import { Link } from 'react-router-dom'
import './Header.css'
import '../index.css'

function dropDownHeader() {}

export default function Header() {
    return (
        <div>
            <div className="row no-margins beauty-by-sofie-small-header-body" style={{display:`none`,overflowX:`hidden`,overflowY:`hidden`}}>
                <div className="col-sm-12 no-padding">
                    <div className="beauty-by-sofie-small-header">
                        <Link to="/">
                            <div className="beauty-by-sofie-small-header-logo">
                                <img src={require('../images/logo/Logo.png')} alt="" />
                            </div>
                        </Link>
                        <nav className="beauty-by-sofie-small-header-nav">
                            <div className="dropdown-toggle beauty-by-sofie-small-header-hamburger">
                                <span onClick={() => dropDownHeader()} className="glyphicon glyphicon-align-justify"></span>
                            </div>
                        </nav>
                    </div>
                    <ul style={{display:`none`}} className="dropdown beauty-by-sofie-small-header-ul">
                        <li>
                            <Link to="/" onClick={() => dropDownHeader()}>Hem</Link>
                        </li>
                        <li>
                            <Link to="/treatments" onClick={() => dropDownHeader()}>Behandlingar</Link>
                        </li>
                        <li>
                            <Link to="/training" onClick={() => dropDownHeader()}>Utbildningar</Link>
                        </li>
                        <li>
                            <Link to="/prices" onClick={() => dropDownHeader()}>Priser</Link>
                        </li>
                        <li>
                            <Link to="/gallery" onClick={() => dropDownHeader()}>Galleri</Link>
                        </li>
                        <li>
                            <Link to="/about" onClick={() => dropDownHeader()}>Om mig</Link>
                        </li>
                        <li>
                            <Link to="/faqs" onClick={() => dropDownHeader()}>FAQs</Link>
                        </li>
                        <li>
                            <Link to="/partners" onClick={() => dropDownHeader()}>Samarbetspartners</Link>
                        </li>
                    </ul>
                </div>
            </div>

            <div className="beauty-by-sofie-long-header-body" id="mainHeader" style={{display:`none`}}>
                <div className="row no-margins">
                    <div className="col-sm-12 beauty-by-sofie-long-header">
                        <div className="row">
                            <div className="col-sm-12 beauty-by-sofie-body-left no-padding">
                                <div className="row">
                                    <div className="col-sm-2 beauty-by-sofie-header no-padding">
                                        <Link to="/">
                                            <img className="test-image" src={require('../images/logo/Logo.png')} alt="" />
                                            <img className="test-image-black" src={require('../images/logo/Logo.png')} alt="" />
                                        </Link>
                                    </div>
                                    <div className="col-sm-10 no-padding">
                                        <div className="beauty-by-sofie-navbar">
                                            <ul className="beauty-by-sofie-navbar-ul no-padding">
                                                <Link to="/treatments">
                                                    <li>Behandlingar</li>
                                                </Link>
                                                <Link to="/training">
                                                    <li>Utbildningar</li>
                                                </Link>
                                                <Link to="/prices">
                                                    <li>Priser</li>
                                                </Link>
                                                <Link to="/gallery">
                                                    <li>Galleri</li>
                                                </Link>
                                                <Link to="/about">
                                                    <li style={{whiteSpace:`nowrap`}}>Om mig</li>
                                                </Link>
                                                <Link to="/faqs">
                                                    <li>FAQs</li>
                                                </Link>
                                                <Link to="/partners">
                                                    <li>Samarbetspartners</li>
                                                </Link>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}