import React, {useState, useEffect} from "react";
import "./Prices.css";
import Header from "../../header/Header";
import Footer from "../../footer/Footer";
import Loading from "../../components/loading/Loading";
import { ServiceCategories } from "../../models/package";
import ApiService from "../../api/ApiService";

export default function Prices() {
    window.onload = () => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
    };

    const emptyState: ServiceCategories = {
        services: []
    };
    const [serviceCategories, setServiceCategories] = useState(emptyState);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        loadServiceCategories();
      }, []);
    
    const loadServiceCategories = () => ApiService.fetchServiceCategories()
    .subscribe(result => {
        setServiceCategories(result);
        setLoading(false);
    });

    if (isLoading || serviceCategories.services.length === 0) {
        return (<Loading></Loading>)
    } else {
        return (
            <div>
                <Header></Header>
                <div className="row beauty-by-sofie-prices-body no-margins">
                    <div className="col-sm-12 beauty-by-sofie-prices-content-box">
                        <div className="beauty-by-sofie-prices-frame">
                            <div className="beauty-by-sofie-prices-content">
                                {
                                    serviceCategories.services.map(category => 
                                        <div className="row beauty-by-sofie-prices-content-row">
                                        <div className="col-sm-12">
                                            <h2 className="animated fadeInUp">{category.name}</h2>
                                            {
                                                category.services.map((service, index) => {
                                                    var c = index % 2 === 0 ? "default-background-color" : ""
                                                    return (
                                                        <div className={"row beauty-by-sofie-prices-inner-content-row animated fadeInUp " + c}>
                                                        <div className="col-sm-4 beauty-by-sofie-prices-content-row-name">{service.name}</div>
                                                        <div className="col-sm-4 beauty-by-sofie-prices-content-row-duration">{service.durationLabel}</div>
                                                        <div className="col-sm-4 beauty-by-sofie-prices-content-row-cost">{service.priceLabel}</div>
                                                    </div>  
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <Footer></Footer>
            </div>
        )
    }
}