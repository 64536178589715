import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ServiceCategories } from "../models/package";
import { ajax } from 'rxjs/ajax';

class ApiService {

    private readonly DATA_URL = '/api/getServiceCategories';

    public fetchServiceCategories = (): Observable<ServiceCategories> => {
        return ajax.get(this.DATA_URL) 
        .pipe(take(1), map(result => result.response as any as ServiceCategories))
    }
}

const singleton = new ApiService();
export default singleton;