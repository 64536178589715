import React, {useState, useEffect} from "react";
import "./ServiceComponent.css";
import { Service } from "../../models/package";
import ApiService from "../../api/ApiService"
import Loading from "../loading/Loading";
import { Link } from "react-router-dom";

interface ServiceProps {
    serviceId: number;
  }

export default function ServiceComponent({ serviceId }: ServiceProps) {

    const emptyState: Service[] = [] as any as Service[];
    const [services, setServices] = useState(emptyState);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => { 
        function loadServices(serviceId: number) {
            ApiService.fetchServiceCategories()
            .subscribe(result => {
                setServices(result.services.filter(s => s.id === serviceId)[0].services);
                setLoading(false);
            });
        }
        loadServices(serviceId);
     }, []);
    

    if (isLoading) {
        return (<Loading></Loading>)
    }
    return (
        <div className="row no-margins">
            <div className="col-sm-12 no-padding">
                <div className="service-component-body">
                {
                    services.map((service, index) => {
                        var c = index % 2 === 0 ? "default-background-color" : ""
                        return (
                            <div key={index} className={"row service-component-content animated fadeInUp " + c}>
                                <h1 className="animated fadeInUp">{service.name}</h1>
                                <h5 className="animated fadeInUp">{service.durationLabel} {service.priceLabel}</h5>
                                <p className="animated fadeInUp">{service.description}</p>
                                <Link className="animated fadeInUp" to={"https://www.bokadirekt.se/boka-tjanst/mandy-medical-4448/" + service.id} target="_blank">Boka</Link>
                            </div>  
                        );
                    })
                }
                </div>
            </div>
        </div>
    )
}