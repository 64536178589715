import React from "react"
import "./About.css"
import Header from '../../header/Header'
import Footer from "../../footer/Footer";

export default function About() {
    window.onload = () => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
    };

    return (
        <div>
            <Header></Header>
            <div className="row about-body no-margins">
                <div className="col-sm-12">
                    <div className="row">
                        <div className="about-body-column-border-rotate-1">
                            <img src={require('../../images/about/About.jpg')} alt="" />
                        </div>
                        <div className="about-body-column-border-rotate-2">
                            <img src={require('../../images/about/AboutWorking.JPG')} alt="" />
                        </div>
                    </div>
                </div>

                <div className="col-sm-12">
                    <div className="row">
                        <div className="col-sm-3"></div>
                        <div className="col-sm-6 about-body-content-text">
                            <h1 className="animated fadeIn">Om mig</h1>
                            <p className="animated fadeIn">Jag heter Sofie Eriksson.</p>
                            <p className="animated fadeIn">
                                2011 utbildade jag mig till make-up artist på International Make-Up Center i Stockholm.
                                Efter det startade jag BLIQ Fransar & Bryn som fick växa vid sidan av mitt dåvarande jobb.
                                Då jag helt ville överge mitt heltidsjobb och enbart fokusera på mitt företag, gick jag en utbildning i ögonfransförlängning 2012.
                                Det var då det började ta fart och jag kunde gå över till heltid något år senare.
                                Sedan dess har jag kompletterat med utbildningar så som lash lift och brow lift.
                            </p>
                            <p className="animated fadeIn lower-text">
                                Jag brinner för att ge mina kunder bästa resultat, samt att kunderna känner sig trygga hos mig.
                                Vi har alltid en kontinuerlig dialog om hur vi ska nå kundens förväntningar.
                                Efter behandlingen ger jag skräddarsydda skötselråd för att kunna bibehålla bästa resultat så länge som möjligt.
                                För både min och din trygghet jobbar jag alltid med munskydd samt håller rummet saniterat, rent och fräscht.
                            </p>
                        </div>
                        <div className="col-sm-3"></div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </div>
    )
}