import React, {useState} from "react";
import { Link } from "react-router-dom";
import "./Treatments.css";
import Header from "../../header/Header";
import Footer from "../../footer/Footer";

export default function Treatments() {
    window.onload = () => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
    };
    
    const advices = {
        lift: [
            "Kom helt osminkad runt ögonen",
            "Behandlingen är helt smärtfri",
            "Efter Lash Lift eller Brow Lift får du inte träna, sola solarium, basta eller blöta fransarna eller brynen på 24 timmar",
            "Efter Lash lift behöver du vänta 48 timmar innan du använder mascara"
        ],
        extension: [
            "Kom helt osminkad runt ögonen",
            "Behandlingen är helt smärtfri",
            "Efter behandlingen får du inte träna, sola solarium, basta eller blöta fransarna på 24 timmar",
            "Använd inga produkter som innehåller olja eller Propylene Glycol vid ögonen, då dessa löser upp limmet",
            "Det rekommenderas inte att använda mascara på din fransförlängning",
            "Borsta dina fransar dagligen med borsten som du får med efter behandlingen",
            "Påfyllning rekommenderas efter 3 till 4 veckor"
        ],
        coloring: [
            "Kom helt osminkad runt ögonen",
            "Behandlingen är oftast smärtfri, men beroende på hur känslig man är kan fransfärgen svida något. Detta är dock helt ofarligt"
        ]
    };

    var [showAdvices, setShowAdvices] = useState([]);

    function openTreatmentsModal(advicesStr: String) {
        var modal = document.getElementById("treatmentsModal");
        if (modal) modal.style.display = "block";
        if (advicesStr === 'lift') setShowAdvices(advices.lift as any);
        if (advicesStr === 'extension') setShowAdvices(advices.extension as any);
        if (advicesStr === 'coloring') setShowAdvices(advices.coloring as any);
    };

    function closeTreatmentsModal() {
        var modal = document.getElementById("treatmentsModal");
        if (modal) modal.style.display = "none";
    };

    window.onclick = function(event) {
        var modal = document.getElementById("treatmentsModal");
        if (modal && event.target === modal) {
            modal.style.display = "none";
        }
    };
    
    return (
        <div>
            <Header></Header>
            <div className="row no-margins treatments-body">
                <div id="treatmentsModal" className="modal">
                    <div className="modal-content-advice">
                        <span onClick={() => closeTreatmentsModal()} className="close">&times;</span>
                        <ul>
                            {
                                showAdvices && showAdvices.map((advice, index) => 
                                    <li key={index}>
                                        <p>{advice}</p>
                                    </li>
                                )
                            }
                        </ul>
                    </div>
                </div>

                <div className="col-sm-12 col-md-12 col-lg-4 treatments-body-column">
                    <div className="treatments-body-column-content">
                        <h1 className="animated fadeInUp">Lash Lift & Brow Lift</h1>
                        <div className="lash-lift-image">
                            <img className="animated fadeInUp" src={require('../../images/treatments/LashLiftBrowLift.JPG')} alt= "" />
                        </div>
                        <p className="animated fadeInUp">Semipermament behandling till dina fransar eller bryn som håller i upp till 8 veckor. Ger illusionen av längre fransar och fylligare bryn.</p>
                        <button className="animated fadeInUp"><Link to="/lifts">Läs mer / Boka</Link></button>
                        <button className="animated fadeInUp make-it-slow" onClick={() => openTreatmentsModal('lift')}>Viktigt att tänka på</button>
                    </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-4 treatments-body-column">
                    <div className="treatments-body-column-content">
                        <h1 className="animated fadeInUp">Fransförlängning</h1>
                        <img className="animated fadeInUp" src={require('../../images/treatments/LashExtension.JPG')} alt="" />
                        <p className="animated fadeInUp">Framhäv dina ögon med hjälp av fransförlängning. Vill du ha naturlig mascara-effekt eller dramatisk volym? Välj den stilen som passar dig.</p>
                        <button className="animated fadeInUp"><Link to="/lashes">Läs mer / Boka</Link></button>
                        <button className="animated fadeInUp make-it-slow" onClick={() => openTreatmentsModal('extension')}>Viktigt att tänka på</button>
                    </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-4 treatments-body-column">
                    <div className="treatments-body-column-content">
                        <h1 className="animated fadeInUp">Färgning av fransar & bryn</h1>
                        <img className="animated fadeInUp" src={require('../../images/treatments/Coloring.JPG')} alt="" />
                        <p className="animated fadeInUp">Skonsam men effektiv och hållbar färg till dina fransar och bryn. Vi plockar dina bryn till den form som passar dig.</p>
                        <button className="animated fadeInUp"><Link to="/colorings">Läs mer / Boka</Link></button>
                        <button className="animated fadeInUp make-it-slow" onClick={() => openTreatmentsModal('coloring')}>Viktigt att tänka på</button>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </div>
    )
}