import React from "react";
import "./Loading.css";

export default function Loading() {
    
    return (
        <div className="loading-content">
            <div className="loading-info">
                <p>Laddar...</p>
            </div>
        </div>
    )
}