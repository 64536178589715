import React, {useState} from "react";
import "./FAQS.css";
import jquery from 'jquery';
import Header from "../../header/Header";
import Footer from "../../footer/Footer";
import { Question } from "../../models/package";

export default function FAQS() {
    const $: JQueryStatic = jquery;
    window.onload = () => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
    };
        
    var questions: Question[] = [
        {id: 1, question: "Behöver jag förbereda mig innan min behandling?", answer: "Det är viktigt att du inte har något smink runt ögonen samma dag som din behandling.\nHar du smink vid ögonen kommer det gå åt mer tid till att göra rent ordentligt, vilket ger mindre tid till att applicera fransarna."},
        {id: 2, question: "Skadar ögonfransförlängningen mina egna ögonfransar?", answer: "Nej! Inte om förlängningen utförs korrekt. Jag anpassar längd, mängd och tjockleken på lösfransen utifrån vad dina egna fransar klarar av. "},
        {id: 3, question: "Hur lång tid tar behandlingen?", answer: "Ett nytt set singel eller volymfransar tar vanligtvis ca 2 timmar. Har du färre fransar kan det ta kortare tid, och har du många egna fransar kan det ta något längre tid."},
        {id: 4, question: "Hur länge sitter fransförlängningen?", answer: "Dina egna fransar växer i cyklar, vilket betyder att de byts ut då och då.\nCykeln kan variera från gång till gång, vissa gånger håller fransarna flera veckor, och ibland kan de lossna lite snabbare. Jag rekommenderar att du fyller på dina fransar efter 2-4 veckor."},
        {id: 5, question: "Kan jag använda mascara på mina lösfransar?", answer: "Ja, men det rekommenderas inte. Mascara resulterar oftast att du tappar fransarna snabbare.\nVill du använda mascara så använd en vattenlöslig, som går att ta bort med bara vatten."},
        {id: 6, question: "Kan jag träna och simma med lösfransarna?", answer: "Ja! Men inte de första 24 timmarna. Därefter kan du både träna och simma/bada utan problem."},
        {id: 7, question: "Vad behöver jag tänka på efter min behandling?", answer: "Fransarna får inte bli blöta eller fuktiga de första 24 timmarna. Undvik därför vatten, träning och solarium det första dygnet.\nAnvänd endast oljefria produkter vid ögonen (olja löser upp limmet och gör att fransarna släpper)\nGnugga dig inte i ögonen och dra inte i fransarna. Då kan du råka rycka bort dina egna fransar vilket kan ge permanenta skador på dina fransar.\nOm du använder mascara - använd en vattenlöslig mascara, och applicera endast i topparna, undvik rötterna där fransarna är fästa.\nUndvik hög värme såsom varma ångor och bastu. Var även försiktig när du öppnar en varm ugn då syntetmaterialet fransarna är gjorda av kan smälta. Så ha inte ansiktet för nära värmen.\nFärga inte fransarna"},
        {id: 8, question: "Vem kan inte göra ögonfransförlängning?", answer: "Den med limm-allergi.\nDen som just genomfört Lasik (ögonlaser) måste vänta minst tre månader och söka läkarens godkännande innan ögonfransförlängning.\nDen som just genomfört ögonlyft  måste vänta 4 till 6 månader efter operationen och behöver också sin läkares godkännande.\nDen som genomgått en cellgiftsbehandling under de senaste 6 månaderna.\nDen som har medicinska tillstånd relaterade till deras ögon, syn eller ögonområde bör först kontakta sin läkare.\n\nÄr du osäker på om du tål limmet kan vi göra ett pricktest någon dag innan."},
        {id: 9, question: "Kan jag göra fransförlängning om jag är gravid?", answer: "Generellt sett är ögonfransförlängningar en säker procedur. Limmet vidrör inte din hud eftersom förlängningarna är limmade på ögonfranshåren.\nAllergiska reaktioner på limmet är sällsynta och reaktioner kan behandlas.\nDäremot kan hormonerna spöka lite, inte minst vid amning.\nDetta kan göra att fransarna inte håller som de brukar."}
    ];

    const [regularQuestion, setRegularQuestion] = useState(questions[0].question);
    const [regularQuestionAnswer, setRegularQuestionAnswer] = useState(questions[0].answer);

    function getAnswer(index: number) {
        $('.beauty-by-sofie-faqs-body-frequently-asked-questions-responses-answer-box-answer').css('visibility', 'visible').hide().fadeIn(1000);
        const newQuestion = questions[index].question;
        const newAnswer = questions[index].answer;
        setRegularQuestion(newQuestion);
        setRegularQuestionAnswer(newAnswer);
    };

    function getAnswerSmall(index: number) {
        const newQuestion = questions[index].question;
        const newAnswer = questions[index].answer;
        setRegularQuestion(newQuestion);
        setRegularQuestionAnswer(newAnswer);
    };    

    var activeTabId = 0;
    function dropMeDown(question: Question) {
        var lastClickedElement = $(".faqs-answer-box-small-" + activeTabId);
        lastClickedElement.slideUp();
        activeTabId = question.id;
        var element = $(".faqs-answer-box-small-" + activeTabId);
        if (element[0] && element[0].style.display === 'none') {
            element.slideDown();
            getAnswerSmall(question.id - 1);
        } else {
            element.slideUp();
        }
    };

    return (
        <div>
            <Header></Header>
            <div className="row no-margins faqs-body-small" style={{display:`none`}}>
                <div className="col-xl-12 faqs-question-list no-padding">
                    <div className="beauty-by-sofie-faqs-body-frequently-asked-questions-box-header animated fadeInUp" style={{textAlign:`center`}}>
                        Vanliga frågor
                    </div>
                    {
                        questions.map((q, index) => 
                            <nav key={index} className="faqs-nav">
                                <div onClick={() => dropMeDown(q)} className="dropdown-toggle faqs-question-small animated fadeInUp"><p>{q.question}</p></div>
                                <div className={"faqs-answer-box-small faqs-answer-box-small-" + q.id} style={{display:`none`}}>
                                    <h5 className="animated fadeInUp">{regularQuestion}</h5>
                                    <p className="animated fadeInUp">{regularQuestionAnswer}</p>
                                </div>
                            </nav>
                        )
                    }
                </div>
            </div>

            <div className="row no-margins beauty-by-sofie-faqs-body margin-top">
                <div className="col-sm-1"></div>
                <div className="col-sm-3 beauty-by-sofie-faqs-body-frequently-asked-questions">
                    <div className="beauty-by-sofie-faqs-body-frequently-asked-questions-box">
                        <div className="beauty-by-sofie-faqs-body-frequently-asked-questions-box-header animated fadeInUp">
                            Vanliga frågor
                        </div>
                        <ul>
                        {
                            questions.map((q, index) => 
                                <li key={index} className={"animated fadeInUp faqs-answer-box-small-" + q.id} onClick={() => getAnswer(index)}><p>{q.question}</p></li>
                            )
                        }
                        </ul>
                    </div>
                </div>
                <div className="col-sm-6 beauty-by-sofie-faqs-body-frequently-asked-questions-responses">
                    <div className="beauty-by-sofie-faqs-body-frequently-asked-questions-responses-answer-box">
                        <div className="beauty-by-sofie-faqs-body-frequently-asked-questions-responses-answer-box-answer">
                            <h5 className="animated fadeInUp">{regularQuestion}</h5>
                            <p className="animated fadeInUp">{regularQuestionAnswer}</p>
                        </div>
                    </div>
                </div>
                <div className="col-sm-2"></div>
            </div>
            <Footer></Footer>
        </div>
    )
}